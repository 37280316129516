/* General form container */
body{
  background-image: url('./Asset/bg.jpg');
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

textarea {
  resize: vertical;
  height: 100px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.error-text {
  color: #d9534f;
  font-size: 12px;
  margin-top: 5px;
}

/* Styling the radio button group */
.radio-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-item input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #007bff;
  border-radius: 50%;
  margin-right: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.radio-item input[type="radio"]:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.radio-item input[type="radio"]:checked::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
}

.radio-item input[type="radio"]:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.7);
}

.radio-item label {
  font-size: 14px;
  cursor: pointer;
}

/* Hover effect */
.radio-item input[type="radio"]:hover {
  border-color: #0056b3;
}

.radio-item input[type="radio"]:checked:hover {
  border-color: #0056b3;
}

/* Disabled state for radio button */
.radio-item input[type="radio"]:disabled {
  background-color: #e0e0e0;
  border-color: #ccc;
  cursor: not-allowed;
}

.radio-item input[type="radio"]:disabled:checked {
  background-color: #ccc;
  border-color: #bbb;
}

/* Form submission button styling */
button:disabled {
  background-color: #b0c4de;
  cursor: not-allowed;
}

/* Other category text input styles */
.form-group input[type="text"] {
  width: 100%;
}

.form-group .dropzone {
  padding: 20px;
  border: 2px dashed #007bff;
  border-radius: 4px;
  text-align: center;
  background-color: #f1f1f1;
  cursor: pointer;
}

.file-list {
  margin-top: 10px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 5px 10px;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.file-item button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.file-item button:hover {
  background-color: #c82333;
}

/* File upload styles */
.dropzone p {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

/* Submit button for form */
.btn-submit {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #218838;
}

.btn-submit:disabled {
  background-color: #b0c4de;
  cursor: not-allowed;
}
.bg{
  background-color: #f1f1f1;
  /* background: transparent; */

  opacity: 80%;
  padding: auto;
  margin-left: 30%;
  margin-right: 30%;
  border-radius: 20px;
}
.bg h1, .bg span{
  color: red;
  opacity: 100%;
}
/* Responsive Styles for mobile devices */
@media (max-width: 600px) {
  .form-container {
    padding: 15px;
  }

  label {
    font-size: 14px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  select {
    font-size: 12px;
    padding: 8px;
  }

  button {
    font-size: 14px;
  }

  .radio-item input[type="radio"] {
    width: 18px;
    height: 18px;
  }

  .radio-item label {
    font-size: 12px;
  }
}